import { getBlob, ref } from 'firebase/storage';
import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { useReleaseData } from '../context/DataContext.js';
import { storage, storageBucketUrl } from '../firebase-config.js';
import Icon from '@mdi/react';
import { mdiArrowLeftBoldCircle, mdiHome, mdiArrowRightBoldCircle } from '@mdi/js';

export function Release({ children }) {
    const { version } = useParams();
    const { releases, refreshReleases } = useReleaseData();

    const [isLoading, setIsLoading] = useState(true);
    const [releaseFound, setReleaseFound] = useState(false);
    const [releaseTitle, setReleaseTitle] = useState();
    const [releaseDate, setReleaseDate] = useState();
    const [releaseVersion, setReleaseVersion] = useState();
    const [releaseVersionFull, setReleaseVersionFull] = useState();
    const [releaseNotes, setReleaseNotes] = useState();
    const [featureReleaseNotes, setFeatureReleaseNotes] = useState();
    const [releaseBeforeLink, setReleaseBeforeLink] = useState("");
    const [releaseBeforeVersion, setReleaseBeforeVersion] = useState("");
    const [releaseAfterLink, setReleaseAfterLink] = useState();
    const [releaseAfterVersion, setReleaseAfterVersion] = useState();

    useEffect(() => {
        setIsLoading(true);
        refreshReleases();
        var releaseItem = releases.find(release => {
            return release.version === version;
        });
        if (releaseItem) {
            const index = releases.indexOf(releaseItem);
            if (index > 0) {
                setReleaseAfterLink("/release/" + releases[index - 1].version);
                setReleaseAfterVersion(releases[index - 1].version);
            }
            if (index < releases.length - 1) {
                setReleaseBeforeLink("/release/" + releases[index + 1].version);
                setReleaseBeforeVersion(releases[index + 1].version);
            }
            setReleaseTitle(releaseItem.versionArray[2] === 0 ? "Release" : "Maintenance release")
            setReleaseDate(releaseItem.date.toDate().toLocaleDateString());
            setReleaseVersion(releaseItem.version);
            setReleaseVersionFull(releaseItem.id);

            // collect until next feature release
            var releaseNotesFile = releaseItem.version + ".html";
            var releaseNotesRef = ref(storage, storageBucketUrl + releaseNotesFile);
            getBlob(releaseNotesRef).then((blob) => {
                blob.text().then((content) => {
                    setReleaseNotes(content);
                    if (releaseItem.versionArray[2] !== "0") {
                        // is maintenance release
                        // append feature release notes
                        // -> find feature release
                        var featureReleaseIndex = index + 1;
                        var featureReleaseItem = releases[featureReleaseIndex];
                        while (featureReleaseItem.versionArray[2] !== "0") {
                            featureReleaseIndex++;
                            featureReleaseItem = releases[featureReleaseIndex];
                        }
                        // get feature relase notes
                        var featureReleaseNotesFile = featureReleaseItem.version + ".html";
                        var featureReleaseNotesRef = ref(storage, storageBucketUrl + featureReleaseNotesFile);
                        getBlob(featureReleaseNotesRef).then((featureBlob) => {
                            featureBlob.text().then((featureContent) => {
                                // separate from maintenance information
                                const separator =
                                    `<div class="has-background-primary-soft p-4">` +
                                    `<div class="has-text-primary title is-2 mb-4 has-text-centered">Nimagna ${featureReleaseItem.version}</div>` +
                                    `<div class="subtitle mt-1 is-6 has-text-centered">Feature release ${featureReleaseItem.id}<br/>Released on ${featureReleaseItem.date.toDate().toLocaleDateString()}</div>` +
                                    `</div>`;
                                setFeatureReleaseNotes(separator + "<div class='p-5 has-background-black-soft'>" + featureContent + "</div>");
                                setReleaseFound(true);
                                setIsLoading(false);
                            })
                        })
                    } else {
                        // is feature release
                        setReleaseFound(true);
                        setIsLoading(false);
                    }
                })
            }).catch((error) => {
                // no release notes found
                setIsLoading(false);
            })
        } else {
            // no release found
            setIsLoading(false);
        }
    }, [releases, refreshReleases, version])

    if (isLoading) {
        return (
            <>
                <div className="columns">
                    <div className="column is-one-fifth"></div>
                    <div className="column is-three-fifths">
                        <div className="p-5 has-background-primary-soft has-text-primary">
                            <h1 className="title is-2 mt-5 ">Loading release {version}...</h1>
                        </div>
                    </div>
                    <div className="column is-one-fifth"></div>
                </div>
            </>
        );
    } else {
        if (releaseFound) {
            return (
                <>
                    <div className="columns">
                        <div className="column is-one-fifth"></div>
                        <div className="column is-three-fifths">
                            <div className="columns mt-3 is-mobile">
                                <div className="column">
                                    {releaseBeforeVersion ?
                                        <Link to={releaseBeforeLink}>
                                            <button className="button"><Icon path={mdiArrowLeftBoldCircle} size={1} className="mr-2" /><div className='is-hidden-mobile'>{releaseBeforeVersion}</div></button>
                                        </Link>
                                        : <></>
                                    }
                                </div>
                                <div className="column has-text-centered">
                                    <Link to="/"><button className="button"><Icon path={mdiHome} size={1} className="mr-2" /><div className='is-hidden-mobile'>Overview</div></button></Link>
                                </div>
                                <div className="column has-text-right">
                                    {releaseAfterVersion ? <Link to={releaseAfterLink}><button className="button"><div className='is-hidden-mobile'>{releaseAfterVersion}</div><Icon path={mdiArrowRightBoldCircle} size={1} className="ml-2" /></button></Link> : <></>}
                                </div>
                            </div>
                            <div className="p-4 has-background-primary-soft">
                                <h1 className="has-text-primary title is-2 mb-4 has-text-centered">Nimagna {releaseVersion}</h1>
                                <h5 className="subtitle mt-1 is-6 has-text-centered">{releaseTitle} {releaseVersionFull}<br />Released on {releaseDate}</h5>
                            </div>
                            <div className="content p-5 has-background-black-soft" dangerouslySetInnerHTML={{ __html: releaseNotes }} />
                            <div className="content" dangerouslySetInnerHTML={{ __html: featureReleaseNotes }} />
                            <div className="columns mt-3 is-mobile">
                                <div className="column">
                                    {releaseBeforeVersion ? <Link to={releaseBeforeLink}><button className="button"><Icon path={mdiArrowLeftBoldCircle} size={1} className="mr-2" /><div className='is-hidden-mobile'>{releaseBeforeVersion}</div></button></Link> : <></>}
                                </div>
                                <div className="column has-text-centered">
                                    <Link to="/"><button className="button"><Icon path={mdiHome} size={1} className="mr-2" /><div className='is-hidden-mobile'>Overview</div></button></Link>
                                </div>
                                <div className="column has-text-right">
                                    {releaseAfterVersion ? <Link to={releaseAfterLink}><button className="button"><div className='is-hidden-mobile'>{releaseAfterVersion}</div><Icon path={mdiArrowRightBoldCircle} size={1} className="ml-2" /></button></Link> : <></>}
                                </div>
                            </div>
                        </div>
                        <div v="column is-one-fifth"></div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="columns">
                        <div className="column is-one-fifth"></div>
                        <div className="column is-three-fifths">
                            <div className="p-5 as-background-primary-soft has-text-primary">
                                <h1 className="title is-2 mt-5 ">Release {version} not found...</h1>
                                <div className="block"><Link to="/">Go back to overview</Link></div>
                            </div>
                        </div>
                        <div className="column is-one-fifth"></div>
                    </div>
                </>
            );
        }

    }
}