import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom'
import { db } from '../firebase-config';

/**
 * The DataContext provides the release information to all react components (see <DataContextProvider> tag in App())
 * The release data can then be accessed (see Releases.js)
 */

const dataContext = createContext();

export function DataContextProvider({ children }) {

    const [searchParams] = useSearchParams();
    const showAll = searchParams.get("show") ? searchParams.get("show") === "all" : false;

    const [releases, setReleases] = useState([]);
    const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : "system");
    const [osTheme, setOsTheme] = useState(null);

    const DEFAULT_THEME = "light";

    function toggleTheme() {
        var currentTheme = theme;
        if (theme === "system") {
            currentTheme = osTheme;
        }
        console.log(currentTheme);
        if (currentTheme === "light") {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    }

    const detectOSTheme = () => {
        if (!window.matchMedia) {
            // matchMedia method not supported
            setOsTheme(DEFAULT_THEME);
        }

        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            // OS theme setting detected as dark
            setOsTheme("dark");
        } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
            setOsTheme("light");
        } else {
            setOsTheme(DEFAULT_THEME);
        }
    };

    async function refreshReleases() {
        if (releases.length > 0) return;

        // get releases collection
        const releaseCollectionRef = collection(db, 'releases');
        const q = query(releaseCollectionRef, orderBy("date", "desc"));
        await getDocs(q)
            .then(async (querySnapshot) => {
                // combine id with data
                let releasesRaw = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                // filter only active
                let filteredReleases = releasesRaw.filter((release) => {
                    return showAll || release.active;
                });
                // extract version information
                filteredReleases.forEach(release => {
                    release.versionArray = release.id.split(".");
                    release.version = `${release.versionArray[0]}.${release.versionArray[1]}.${release.versionArray[2]}`;
                });
                // set data
                setReleases(filteredReleases);
            }).catch((e) => {
                console.error("Error getting releases: ", e);
            });
    }

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        refreshReleases();
        detectOSTheme();
    });

    useEffect(() => {
        if (theme === "system") {
            document.documentElement.setAttribute("data-theme", osTheme);
        } else {
            document.documentElement.setAttribute("data-theme", theme);
        }
    }, [theme, osTheme]);

    return (
        <dataContext.Provider
            value={{ releases, refreshReleases, theme, toggleTheme }}
        >
            {children}
        </dataContext.Provider>
    );
}

export function useReleaseData() {
    return useContext(dataContext);
}
