import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Use development Firebase project NimagnaTest
// If set to false, the production environment will be used. 
const useDevelopmentEnvironment = true;
// Use local emulator during development. 
// If set to false, the productive or test environment will be used according to the above.
const useEmulatorLocally = false;
// Firebase configurations
const firebaseConfigProductive = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};
const firebaseConfigDevelopment = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV
};

// Initialize Firebase
var storageBucket = "gs://nimagnatest.appspot.com/releases/";
// eslint-disable-next-line no-restricted-globals
const currentLocation = location.hostname;
try {
    if (currentLocation === "release.nimagna.com" || !useDevelopmentEnvironment) {
        console.log("Productive environment!");
        storageBucket = "gs://nimagna-ag.appspot.com/releases/";
        initializeApp(firebaseConfigProductive);
    } else {
        console.log("Test environment!");
        initializeApp(firebaseConfigDevelopment);
    }
} catch (error) {
    console.warn("Firebase error");
}

export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();
export const storageBucketUrl = storageBucket;

if (useDevelopmentEnvironment && useEmulatorLocally && currentLocation === "localhost") {
    console.log("Connecting to emulator");
    connectFirestoreEmulator(db, '127.0.0.1', 8080);
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectStorageEmulator(storage, '127.0.0.1', 9199);
}
